/*

---
title: Youtube Video Embeds
name: youtube-embed
category: Javascript
---

 */
define( 'app/youtube-embed',[] , function()
{
	var YOUTUBE = function ( options )
	{
		this.$link = options.link;

		// Clean up the markup to make sure we've got the right element
		this.clean_up();

		// This should be the link to the video on YouTube
		this.url = this.$link.getAttribute( 'href' );

		// We'll use this to check for things to override/test
		var disable_facade = new URLSearchParams( window.location.search ).get( 'disable_facade' );

		// Disable the facade if the flag is set in the options or via a query arg
		this.disable_facade = options.disable_facade || disable_facade || false;

		// Force autoplay? Eg; for use in video-only modals
		this.force_autoplay = options.force_autoplay || false;

		// Abandon if there's no link
		if ( !this.$link ) return false;

		// Do not initialise inside a (hidden) mixed media row video modal content container
		if( this.$link.matches( '[id^=media_youtube_embed_] *' ).length ) return false;

		// Figure out the Youtube ID - abandon if none found
		if( !this.get_video_id() ) return false;

		// 
		if( !this.disable_facade )
		{
			// Create the facade
			this.$facade = this.create_facade();
			this.$link.replaceWith( this.$facade );
		}
		else
		{
			// Make the embedd element
			this.$embed = this.create_embed();
			this.$link.replaceWith( this.$embed );
		}
	};

	// --------------------------------------------------
	
	YOUTUBE.prototype.clean_up = function()
	{
		// Check if this is a <p> and fallback to a child <a> if exists
		if( this.$link.matches( 'p' ) )
		{
			this.$link = this.$link.querySelector( 'a' );
		}

		// Remove wrapper paragraph, if any
		if( this.$link.parentElement.matches( 'p' ) )
		{
			// Get the link's parent paragraph
			var $parent = this.$link.parentElement;
			var $grandparent = $parent.parentElement;

			// Move the link out to the grandparent
			$grandparent.insertBefore( this.$link , $parent );

			// Remove the empty paragraph
			$grandparent.removeChild( $parent );
		}
	};

	// --------------------------------------------------
	
	YOUTUBE.prototype.get_video_id = function()
	{
		// figure out the Youtube ID, which can be in the following 3 formats:
		// - [old style] https://www.youtube.com/watch?v=_8pUffDWFlM
		// - [old style extended] https://www.youtube.com/watch?v=_8pUffDWFlM&index=1&list=PLqL9vrHSa70NmzsSg36tnv0dqEueEbifj
		// - [new style] https://youtu.be/_8pUffDWFlM - with or without time stamp (t=XYmXYs)

		var video_id = '';

		if( this.url.indexOf( 'watch' ) > 0 ) // Old style 
		{
			video_id = this.url.substr( this.url.indexOf( 'v=' ) + 2, 11 );
		}
		else if( this.url.indexOf( 'youtu.be' ) > 0 ) // New style
		{
			video_id = this.url.substr( this.url.indexOf( '.be/' ) + 4, 15 );
		}
		else
		{
			// Can't find a vide ID :(
			return false;
		}

		// Remove any query string
		video_id = video_id.replace(/\?.*$/gm, "");

		// Save the video ID
		this.video_id = video_id;

		return video_id;
	};
	
	// --------------------------------------------------
	
	YOUTUBE.prototype.create_facade = function()
	{
		var image_src = 'https://i.ytimg.com/vi/' + this.video_id + '/sddefault.jpg';
		
		var $facade = document.createElement( 'div' );
		var $facade_link = document.createElement( 'a' );
		var $facade_image = document.createElement( 'img' );

		$facade.classList.add( 'c-video' );
		$facade.classList.add( 'c-video--facade' );
		
		$facade_link.classList.add( 'c-video__link' );
		$facade_link.setAttribute( 'href' , this.url );
		$facade_link.setAttribute( 'aria-label' , this.url );
		$facade_link.innerHTML = '<i class="c-icon c-icon--youtube-play c-icon--4x"></i>';
		
		$facade_image.classList.add( 'c-video__placeholder' );
		$facade_image.setAttribute( 'src' , image_src );
		$facade_image.setAttribute( 'loading' , 'lazy' );
		$facade_image.setAttribute( 'width' , 640 );
		$facade_image.setAttribute( 'height' , 480 );
		$facade_image.setAttribute( 'alt' , '' );

		// Check that the thumbnail variant we've picked actually exists
		$facade_image.addEventListener( 'load' , this.facade_image_load_handler.bind( this ) );
		
		// Add the click handler to create/activate the embed
		$facade_link.addEventListener( 'click' , this.facade_click_handler.bind( this ) );

		// Put it all together
		$facade_link.append( $facade_image );
		$facade.append( $facade_link );

		return $facade;
	};

	// --------------------------------------------------

	YOUTUBE.prototype.facade_image_load_handler = function( event )
	{
		// The "broken" image we get back from YouTube is 120x90.
		// We'll assume there's no sddefault if the image is that size.
		if( event.target.naturalWidth == 120 && event.target.naturalHeight == 90 )
		{
			// If that's the case then change to hqdefault
			event.target.setAttribute( 'src' , 'https://i.ytimg.com/vi/' + this.video_id + '/hqdefault.jpg' );
			event.target.setAttribute( 'width' , 480 );
			event.target.setAttribute( 'height' , 360 );
		}
	};

	// --------------------------------------------------

	YOUTUBE.prototype.facade_click_handler = function( event )
	{
		event.preventDefault();

		// Make the embedd element
		this.$embed = this.create_embed();

		// Replace the facade element with the embed code
		this.$facade.replaceWith( this.$embed );

		// Set focus for a11y
		this.$iframe.focus();
	};

	// --------------------------------------------------

	YOUTUBE.prototype.create_embed = function()
	{
		// Get any additional options from link data attributes
		
		var option_keys = [ "mute" , "cc_load_policy" , "t" ];
		
		var options_query_string = "";
		
		for( var a = 0 ; a < option_keys.length ; a++ )
		{
			if( $( this.$link ).attr( "data-" + option_keys[a] ) )
			{
				options_query_string += "&" + option_keys[a] + "=" + $( this.$link ).attr( "data-" + option_keys[a] );
			}
		}

		// Unless the facade was disabled or explicitly told to autoplay, play the video straight away
		if( !this.disable_facade || this.force_autoplay ) options_query_string += '&autoplay=1';

		// Build the URL for the iframe
		var iframe_src = "//www.youtube-nocookie.com/embed/" + this.video_id + "?enablejsapi=1&rel=0" + options_query_string;

		// Create the embed container
		
		var $embed = document.createElement( 'div' );
		
		$embed.classList.add( 'c-video' );
		$embed.setAttribute( 'data-video-id' , this.video_id );

		// Create the actual iframe 

		var $iframe = document.createElement( 'iframe' );

		$iframe.setAttribute( 'src' , iframe_src );
		$iframe.setAttribute( 'loading' , 'lazy' );
		$iframe.setAttribute( 'allowfullscreen' , 'true' );
		$iframe.setAttribute( 'allow' , 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' );

		// Try to get a video name to set as the iframe's title attribute

		fetch( "https://www.googleapis.com/youtube/v3/videos/?part=snippet&id=" + this.video_id + "&key=AIzaSyBMXKei1d7in0xiNuk0kVarPgsUyhTSLkc" )
		.then( function( response )
		{
			return response.json();
		})
		.then( function( data )
		{
			try
			{
				$iframe.setAttribute( 'title' , data.items[0].snippet.title );
			}
			catch( error )
			{
				$iframe.setAttribute( 'title' , 'Embedded video' );
			}
		})
		.catch( function( error )
		{
			$iframe.setAttribute( 'title' , 'Embedded video' );
		});

		// Add to container
		$embed.append( $iframe );

		// Save the iframe for later
		this.$iframe = $iframe;

		return $embed;
	};

	return YOUTUBE;
});

